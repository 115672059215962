import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
//import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                {/** Qué es¿? */}
                <Title>Curs Online per la Policia Local de Castelldefels 2024</Title>
                <Text>
                    Si quieres acceder a la Policía local de Castelldefels 2024 te presentamos el
                    mejor curso online
                    <br />
                    <br />
                    Antes de nada, decirte que en la anterior convocatoria prácticamente todos los
                    aspirantes que fueron aptos eran alumnos nuestros incluido el número 1.
                    <br />
                    <br />
                    iOpos Academia vuelve este año con un campus online aún más completo.
                    <br />
                    <br />
                    Un campus que se adapta a ti y a tus horarios.
                    <br />
                    <br />
                    El único campus que desde que te matriculas tienes acceso a todos los contenidos
                    sin límite y donde se irá añadiendo nuevo contenido.
                    <br />
                    <br />
                    Dentro del campus encontrarás.
                    <br />
                    <br />
                    -Todas las noticias de actualidad semanales y test para ponerse a prueba.
                    <br />
                    <br />
                    -Información sobre el consistorio, distritos, la ciudad y curiosidades.
                    <br />
                    <br />
                    -Cultura general básica, avanzada y videos quiz por temáticas
                    <br />
                    <br />
                    -Los 40 temas del temario exigidos en las bases, ideas fuerza de cada tema y
                    test para ponerse a prueba.
                    <br />
                    <br />
                    -7 exámenes oficiales de las últimas convocatorias de Castelldefels.
                    <br />
                    <br />
                    -La prueba práctica y explicaciones.
                    <br />
                    <br />
                    -Todos los premios, deportes y personalidades
                    <br />
                    <br />
                    Y simulacros ómnibus para comprobar tu nivel
                    <br />
                    <br />
                    ¿Ahora entiendes por qué nuestros alumnos obtienen las mejores notas año tras
                    año?
                    <br />
                    <br />
                    Y si te ha parecido poco… por ser alumno tendrás acceso a un grupo de whatsapp
                    donde semanalmente tendrás kahoots y retos de nivel.
                    <br />
                    <br />
                    El curso estará disponible hasta el día del examen.
                    <br />
                    <br />
                    Por solo 79.99 de un solo pago.
                    <br />
                    <br />
                    Accede ya al curso y fórmate con garantías
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/KHolnbmCzZk"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
